import { DialogUtility } from "@syncfusion/ej2-popups";
import React, { PropsWithChildren, useContext, useEffect } from "react";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFDetailFieldCustom from "../../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import {
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import { hasPermissions } from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import {
  BrowserIsDevice,
  RAFActionStatus,
  RAFButtonConstant,
  RAFDocumentTypeName,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import { ContentLibraryRow } from "../Library/ContentLibraryRow";
import { ContentLibraryCurrentStatus } from "../Library/DocumentHelper";
import {
  getRecipientsByAcknowledgementTrackingDocumentUID,
  saveMultipleAcknowledgementTrackingItems,
} from "./AcknowledgementTrackingHelper";
import AcknowledgementTrackingListItemTabContent from "./AcknowledgementTrackingListItemTabContent";
import {
  AcknowledgementTrackingRow,
  AcknowledgementTrackingSaveRow,
} from "./AcknowledgementTrackingRow";

const getAcknowledgementTabItems = (mode: "default" | "Overview") => {
  const acknowledgementTabItems: {
    id: string;
    text: string;
    permissionNames: string[];
  }[] =
    mode === "Overview"
      ? [
        {
          id: "RequestedAcknowledgement",
          text: "Acknowledgement Requests",
          permissionNames: [PermissionConstants.DocumentAcknowledgementTrackingSend]
        },
      ]
      : [
        {
          id: "NewRequest",
          text: "New Request",
          permissionNames: [PermissionConstants.DocumentAcknowledgementTrackingSend],
        },
        {
          id: "RequestedAcknowledgement",
          text: "Acknowledgement Requests",
          permissionNames: [
            PermissionConstants.DocumentAcknowledgementTrackingSend,
            PermissionConstants.DocumentAcknowledgementTrackingView],
        },
      ];

  return acknowledgementTabItems;
};

interface IProps {
  contentLibraryUID: string;
  onClose: () => void;
  onSave: () => void;
  mode?: "default" | "Overview";
  selectedTabIdAcknowledgementTracking?:
  | "NewRequest"
  | "RequestedAcknowledgement";
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  isAcknowledgementTrackingUpdated: boolean;
  contentLibraryRow: ContentLibraryRow;
  recipientsAcknowledgementTrackingList: AcknowledgementTrackingRow[];
  acknowledgementTrackingRetrieveResponseRow: AcknowledgementTrackingSaveRow;
  selectedTabId: "NewRequest" | "RequestedAcknowledgement";
  acknowledgementPermissionTabItems: {
    id: string;
    text: string;
    permissionNames: string[];
  }[];
  newAcknowledgementTrackingRetrieveResponseRow: AcknowledgementTrackingSaveRow;
}

function AcknowledgementTrackingDialogContent({
  contentLibraryUID,
  mode = "default",
  ...props
}: PropsWithChildren<IProps>) {
  const outerDivId = `raf_dlg_Outer_Div_${RAFEntityName.AcknowledgementTracking}`;
  let newAcknowledgementTrackingDialog: any;

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const [state, setState] = React.useReducer<
    React.Reducer<IState, Partial<IState>>
  >((state, newState) => ({ ...state, ...newState }), {
    isLoading: true,
    noContent: false,
    contentLibraryRow: null,
    isAcknowledgementTrackingUpdated: false,
    recipientsAcknowledgementTrackingList: null,
    acknowledgementTrackingRetrieveResponseRow: null,
    selectedTabId: null,
    newAcknowledgementTrackingRetrieveResponseRow: null,
    acknowledgementPermissionTabItems: null
  });

  async function checkScrollable() {
    const div = await document.querySelector(
      "#raf_tab_header_acknowledgement_tracking"
    );
    const leftButton = await document.getElementById(
      "btn_raf_tab_header_acknowledgement_tracking_left_scroll_iconBtn"
    );
    const rightButton = await document.getElementById(
      "btn_raf_tab_header_acknowledgement_tracking_right_scroll_iconBtn"
    );

    if (div && leftButton && rightButton) {
      // Check if the div is scrollable
      if (div.scrollWidth > div.clientWidth) {
        // The div is scrollable, show the arrow buttons
        leftButton.style.display = "block";
        rightButton.style.display = "block";

        // Add click event listeners to the arrow buttons
        leftButton.addEventListener("click", () => {
          // Scroll the div to the left
          div.scrollTo({
            left: div.scrollLeft - (div.clientWidth - 100),
            behavior: "smooth",
          });
        });

        rightButton.addEventListener("click", () => {
          // Scroll the div to the right
          div.scrollTo({
            left: div.scrollLeft + (div.clientWidth - 100),
            behavior: "smooth",
          });
        });
      } else {
        // The div is not scrollable, hide the arrow buttons
        leftButton.style.display = "none";
        rightButton.style.display = "none";
      }

      // Stop observing the DOM changes
      observer.disconnect();
    }
  }

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(checkScrollable);

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  useEffect(() => {
    refresh(false);
  }, []);

  const refresh = async (isAcknowledgementTrackingUpdated: boolean) => {
    setState({
      isLoading: true,
      isAcknowledgementTrackingUpdated,
      newAcknowledgementTrackingRetrieveResponseRow: null,
    });

    const acknowledgementPermissionTabItems = getAcknowledgementTabItems(mode).filter((x) => {
      return isNotEmptyArray(x.permissionNames) &&
        isNotNullAndUndefined(permissionValue)
        ? hasPermissions(permissionValue, x.permissionNames)
        : true;
    });

    if (isNotNullAndUndefined(contentLibraryUID) && isNotEmptyArray(acknowledgementPermissionTabItems)) {
      const [
        contentLibraryRow,
        recipientsByAcknowledgementTrackingDocumentUID,
      ] = await Promise.all([
        RetrieveRecord(contentLibraryUID, RAFEntityName.ContentLibrary),
        getRecipientsByAcknowledgementTrackingDocumentUID(contentLibraryUID),
      ]);

      if (isNotNullAndUndefined(contentLibraryRow)) {
        const recipientsAcknowledgementTrackingList = isNotNullAndUndefined(
          recipientsByAcknowledgementTrackingDocumentUID
        )
          ? recipientsByAcknowledgementTrackingDocumentUID.recipientsAcknowledgementTrackingList
          : null;
        const acknowledgementTrackingItemRow = isNotNullAndUndefined(
          recipientsByAcknowledgementTrackingDocumentUID
        )
          ? recipientsByAcknowledgementTrackingDocumentUID.acknowledgementTrackingItemRow
          : null;

        const acknowledgementTrackingRetrieveResponseRow: AcknowledgementTrackingSaveRow =
          isNotEmptyArray(recipientsAcknowledgementTrackingList)
            ? new AcknowledgementTrackingSaveRow()
            : null;

        if (isNotEmptyArray(recipientsAcknowledgementTrackingList)) {
          acknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients =
            [];

          recipientsAcknowledgementTrackingList.map((item) => {
            const recipients = acknowledgementTrackingItemRow.Recipients;
            const recipientItem = isNotNullAndUndefined(recipients)
              ? recipients.find((x) => x.UID === item.SignedByUID)
              : null;
            if (isNotNullAndUndefined)
              acknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients.push(
                {
                  UID: item.SignedByUID,
                  Value: item.SignedBy,
                  Type: isNotNullAndUndefined(recipientItem)
                    ? recipientItem.Type
                    : null,
                }
              );
          });
          acknowledgementTrackingRetrieveResponseRow.RecordType = "Document";
          acknowledgementTrackingRetrieveResponseRow.DocumentUID =
            contentLibraryRow.UID;
          acknowledgementTrackingRetrieveResponseRow.Document =
            contentLibraryRow.DisplayName;
          acknowledgementTrackingRetrieveResponseRow.Status =
            RAFActionStatus.Pending;
          acknowledgementTrackingRetrieveResponseRow.VersionNumber =
            contentLibraryRow.Revision;
        }

        let defaultSelectedTabId = mode === "Overview"
          ? "RequestedAcknowledgement"
          : isNotNullAndUndefined(props.selectedTabIdAcknowledgementTracking)
            ? props.selectedTabIdAcknowledgementTracking
            : "NewRequest";

        if (isNotEmptyArray(acknowledgementPermissionTabItems)) {
          const defaultSelectedTabIdExists = acknowledgementPermissionTabItems.some(item => item.id === defaultSelectedTabId);
          if (!defaultSelectedTabIdExists) {
            const firstTab = acknowledgementPermissionTabItems[0];
            defaultSelectedTabId = firstTab.id as "RequestedAcknowledgement" | "NewRequest";
          }
        } else {
          defaultSelectedTabId = null;
        }

        setState({
          isLoading: false,
          noContent: false,
          contentLibraryRow,
          recipientsAcknowledgementTrackingList,
          acknowledgementTrackingRetrieveResponseRow,
          acknowledgementPermissionTabItems,
          selectedTabId: defaultSelectedTabId,
        });
      } else {
        setState({ noContent: true, isLoading: false });
      }
    } else {
      setState({ noContent: true, isLoading: false });
    }
  };

  const onSaveButtonClick = async () => {
    const { newAcknowledgementTrackingRetrieveResponseRow } = state;
    if (isNotNullAndUndefined(newAcknowledgementTrackingRetrieveResponseRow)) {
      let progressDiv = showProgress(`#${outerDivId}`);

      const response = await saveMultipleAcknowledgementTrackingItems(
        newAcknowledgementTrackingRetrieveResponseRow
      );
      hideProgress(progressDiv);
      setState({ selectedTabId: "RequestedAcknowledgement" });
      refresh(true);
    }
  };

  const oncancelAcknowledgementTracking = () => {
    setState({ newAcknowledgementTrackingRetrieveResponseRow: null });
  };

  const onUpdateNewAcknowledgementTrackingRetrieveResponseRow = (
    newAcknowledgementTrackingRetrieveResponseRow: AcknowledgementTrackingSaveRow
  ) => {
    if (state.selectedTabId === "NewRequest") {
      const { acknowledgementTrackingRetrieveResponseRow } = state;
      const newAcknowledgementRecipients = isNotNullAndUndefined(
        newAcknowledgementTrackingRetrieveResponseRow
      )
        ? newAcknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients
        : null;
      const acknowledgementRecipients = isNotNullAndUndefined(
        acknowledgementTrackingRetrieveResponseRow
      )
        ? acknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients
        : null;

      const newAcknowledgementRecipientsLength = isNotEmptyArray(
        newAcknowledgementRecipients
      )
        ? newAcknowledgementRecipients.length
        : 0;
      const acknowledgementRecipientsLength = isNotEmptyArray(
        acknowledgementRecipients
      )
        ? acknowledgementRecipients.length
        : 0;

      if (
        isNotEmptyArray(newAcknowledgementRecipients) &&
        newAcknowledgementRecipientsLength > acknowledgementRecipientsLength
      ) {
        setState({ newAcknowledgementTrackingRetrieveResponseRow });
      } else {
        setState({ newAcknowledgementTrackingRetrieveResponseRow: null });
      }
    } else {
      setState({ newAcknowledgementTrackingRetrieveResponseRow });
    }
  };

  const onCloseIconClick = () => {
    if (state.isAcknowledgementTrackingUpdated) {
      if (props.onSave) {
        props.onSave();
      }
    } else {
      if (props.onClose) {
        props.onClose();
      }
    }
  };

  const tabSelected = (
    selectedTabId: "RequestedAcknowledgement" | "NewRequest"
  ) => {
    if (
      isNotNullAndUndefined(state.newAcknowledgementTrackingRetrieveResponseRow)
    ) {
      ShowDirtyFieldsAlert(selectedTabId);
    } else {
      setState({
        selectedTabId,
        newAcknowledgementTrackingRetrieveResponseRow: null,
      });
    }
  };

  const ShowDirtyFieldsAlert = (
    selectedTabId: "RequestedAcknowledgement" | "NewRequest"
  ) => {
    newAcknowledgementTrackingDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: {
        text: "Discard",
        click: forceSwitchTab.bind(this, selectedTabId),
      },
      closeOnEscape: false,
      content: `You have unsaved changes. You can save your changes, discard them, or cancel to continue editing.`,
      okButton: {
        text: "Save",
        click: forceSaveAndSwitchTab.bind(this, selectedTabId),
      },
      //title: `Are sure you want to leave the page?`,
      title: `Unsaved Changes`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
      showCloseIcon: true,
    });
  };

  const forceSaveAndSwitchTab = (
    selectedTabId: "RequestedAcknowledgement" | "NewRequest"
  ) => {
    onSaveButtonClick();
    newAcknowledgementTrackingDialog.hide();
    setState({
      selectedTabId,
      newAcknowledgementTrackingRetrieveResponseRow: null,
    });
  };

  const forceSwitchTab = (
    selectedTabId: "RequestedAcknowledgement" | "NewRequest"
  ) => {
    newAcknowledgementTrackingDialog.hide();
    setState({
      selectedTabId,
      newAcknowledgementTrackingRetrieveResponseRow: null,
    });
  };

  if (state.isLoading === false) {
    if (state.noContent === false) {
      const { contentLibraryRow } = state;

      return (
        <div className="e-dlg-content-outer" id={outerDivId}>
          <div className="e-dlg-header-content pt-0 pb-0">
            <div className="w-100">
              <div className="row gx-3 align-items-center flex-nowrap">
                <div className="col overflow-auto">
                  <div
                    id="raf_tab_header_acknowledgement_tracking_container"
                    className="raf_tab_header raf_lg secondary_tab hideScrollBar"
                  >
                    {!BrowserIsDevice && (
                      <RAFButtonComponent
                        iconBtn
                        iconCss="fas fa-chevron-left"
                        className="bg-white border-0 custom-button-lg"
                        id="raf_tab_header_acknowledgement_tracking_left_scroll"
                        enableToolTip={false}
                      ></RAFButtonComponent>
                    )}
                    <div
                      id="raf_tab_header_acknowledgement_tracking"
                      className="raf_tab_header raf_lg hideScrollBar"
                    >
                      {state.acknowledgementPermissionTabItems.map((x) => {
                        return (
                          <div
                            className={`raf_tab_header_item${state.selectedTabId === x.id ? " active" : ""
                              }`}
                            key={x.id}
                            onClick={() =>
                              tabSelected(
                                x.id as
                                | "RequestedAcknowledgement"
                                | "NewRequest"
                              )
                            }
                          >
                            <span>{x.text}</span>
                          </div>
                        );
                      })}
                    </div>
                    {!BrowserIsDevice && (
                      <RAFButtonComponent
                        iconBtn
                        iconCss="fas fa-chevron-right"
                        className="bg-white border-0 custom-button-lg"
                        id="raf_tab_header_acknowledgement_tracking_right_scroll"
                        enableToolTip={false}
                      ></RAFButtonComponent>
                    )}
                  </div>
                </div>
                <div className="col-auto">
                  <RAFButtonComponent
                    displayMode="DialogCloseBtn"
                    onClick={onCloseIconClick}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="e-dlg-body-content">
            <>
              <div className="row gx-0 gy-3">
                {mode === "default" && (
                  <div className="col-12">
                    <CustomCardWidget>
                      <div className="row g-2">
                        <RAFDetailFieldCustom
                          value={contentLibraryRow.DisplayName}
                          displayValue={contentLibraryRow.DisplayName}
                          title="Title"
                          colClassName="col-md"
                          rowClassName="gx-0 gy-1"
                          labelClassName="subtitle_3 medium"
                        />
                        <RAFDetailFieldCustom
                          value={contentLibraryRow.CurrentStatus}
                          displayValue={contentLibraryRow.CurrentStatus}
                          title="Status"
                          moduleName={RAFEntityName.ContentLibrary}
                          isColorOption
                          mode="discView"
                          field="CurrentStatus"
                          colClassName="col-md"
                          rowClassName="gx-0 gy-1"
                          labelClassName="subtitle_3 medium"
                        />
                        <RAFDetailFieldCustom
                          value={contentLibraryRow.DocumentType}
                          displayValue={contentLibraryRow.DocumentType}
                          title="Type"
                          field="DocumentType"
                          moduleName={RAFEntityName.ContentLibrary}
                          iconName={
                            isNotNullAndUndefined(
                              RAFDocumentTypeName[
                              contentLibraryRow.DocumentType
                              ]
                            )
                              ? RAFDocumentTypeName[
                                contentLibraryRow.DocumentType
                              ].IconClass
                              : "fa fa-file"
                          }
                          isColorOption
                          colClassName="col-md"
                          rowClassName="gx-0 gy-1"
                          labelClassName="subtitle_3 medium"
                          valueClassName="raf_badge"
                        />
                      </div>
                    </CustomCardWidget>
                  </div>
                )}
                {state.contentLibraryRow.CurrentStatus ===
                  ContentLibraryCurrentStatus.Published ? (
                  <div className="form-group-margin-0 col-12">
                    <AcknowledgementTrackingListItemTabContent
                      onUpdateNewAcknowledgementTrackingRetrieveResponseRow={
                        onUpdateNewAcknowledgementTrackingRetrieveResponseRow
                      }
                      contentLibraryRow={state.contentLibraryRow}
                      newAcknowledgementTrackingRetrieveResponseRow={
                        state.newAcknowledgementTrackingRetrieveResponseRow
                      }
                      recipientsAcknowledgementTrackingList={
                        state.recipientsAcknowledgementTrackingList
                      }
                      acknowledgementTrackingRetrieveResponseRow={
                        state.acknowledgementTrackingRetrieveResponseRow
                      }
                      mode={mode}
                      selectedTabId={state.selectedTabId}
                    />
                  </div>
                ) : (
                  <div className="p-3 py-2 columnChooserPanel customScrollBar">
                    <RAFDeletedRecordState title="Document not published." />
                  </div>
                )}
              </div>
            </>
          </div>
          <div className="e-dlg-footerContent ">
            {isNotNullAndUndefined(
              state.newAcknowledgementTrackingRetrieveResponseRow
            ) ? (
              <>
                <div className="row gx-2">
                  <div className="col-auto">
                    <RAFButtonComponent
                      action={RAFButtonConstant.Save}
                      {...(state.selectedTabId === "NewRequest" && {
                        btnContent: "Send Request",
                      })}
                      onClick={() => onSaveButtonClick()}
                      className="btn_brand_primary semi_dark new_style raf_sm"
                    />
                  </div>
                  <div className="col-auto">
                    <RAFButtonComponent
                      action={RAFButtonConstant.Cancel}
                      className="new_style raf_sm"
                      onClick={oncancelAcknowledgementTracking}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row gx-2">
                  <div className="col-auto">
                    <RAFButtonComponent
                      action={RAFButtonConstant.Close}
                      className="new_style raf_sm"
                      onClick={onCloseIconClick}
                      showIcon={false}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="e-dlg-content-outer" id={outerDivId}>
          <div className="e-dlg-header-content pt-0 pb-0">
            <div className="w-100">
              <div className="row gx-3 align-items-center flex-nowrap">
                <div className="col-auto">
                  <RAFButtonComponent
                    displayMode="DialogCloseBtn"
                    onClick={onCloseIconClick}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="e-dlg-body-content">
            <div className="p-3 py-2 columnChooserPanel customScrollBar">
              <RAFDeletedRecordState title="You do not have sufficient privileges to edit this item." />
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="container-fluid px-0">
        <ACLoadingPanel loadingText="Preparing Data..." />
      </div>
    );
  }
}

export default React.memo(AcknowledgementTrackingDialogContent);
