import { MenuEventArgs } from "@syncfusion/ej2-navigations";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import moment from "moment";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { GridColumnTemplates } from "../../../../RAFComponents/Grid/RAFSFGridUtils";
import RAFIconImage from "../../../../RAFComponents/Navigation/RAFIconImage";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  IsNullOrWhiteSpace,
  getFileExtension,
  isEmptyOrNull,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import { QueryAttributeJM } from "../../../../RAFComponents/models/Common/QueryAttributeJM";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import RAFDetailsValueWithSeparator from "../../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";

export const VersionHistoryColumnTemplates: GridColumnTemplates[] = [
  { key: "ProcessName", value: "versionHistoryTemplate" },
  { key: "Revision", value: "revisionTemplate" },
  { key: "DueDate", value: "dateTemplate" },
  { key: "StartDate", value: "dateTimeTemplate" },
  { key: "EndDate", value: "dateTimeTemplate" },
  { key: "ModifiedDate", value: "dateTimeTemplate" },
  { key: "CreatedDate", value: "dateTimeTemplate" },
  { key: "LastActivityDate", value: "updatedDateTemplate" },
  { key: "FileSize", value: "documentFileSizeTemplate" },
];

export const VersionHistoryColumnListTemplates: GridColumnTemplates[] = [
  { key: "ProcessName", value: "versionHistoryListTemplate" },
  { key: "Revision", value: "revisionListTemplate" },
  { key: "DueDate", value: "dateTemplate" },
  { key: "StartDate", value: "dateTimeTemplate" },
  { key: "EndDate", value: "dateTimeTemplate" },
  { key: "ModifiedDate", value: "dateTimeTemplate" },
  { key: "CreatedDate", value: "dateTimeTemplate" },
  { key: "LastActivityDate", value: "updatedDateTemplate" },
  { key: "FileSize", value: "documentFileSizeTemplate" },
];
export const ChecklistItemTransVersionHistoryColumnListTemplates: GridColumnTemplates[] =
  [
    {
      key: "VersionNumber",
      value: "checklistItemTransVersionHistoryListTemplate",
    },
  ];

export const versionHistoryTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const processUID = data["UID"];
  const stepCount = data["StepCount"];
  const iconName: IconList = data["IconName"];
  const completedCount = data["CompletedCount"];
  const VersionNumber = data["VersionNumber"];
  let totalSteps =
    isNotNullAndUndefined(stepCount) && stepCount > 0 ? stepCount : 0;
  let completedSteps =
    isNotNullAndUndefined(completedCount) && completedCount > 0
      ? completedCount
      : 0;
  let bpStepRow = [];
  for (let i = 0; i < totalSteps; i++) {
    if (i < completedSteps) {
      bpStepRow.push(<li key={i} className="bp-step-li completed"></li>);
    } else {
      bpStepRow.push(<li key={i} className="bp-step-li"></li>);
    }
  }

  let versionNumber = isNotNullAndUndefined(VersionNumber)
    ? " (v" + VersionNumber + ")"
    : "";

  if (isNotNullAndUndefined(fieldValue)) {
    return (
      <div className="gridDefault d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center w-100">
          <div className="bp-template-item hidden">
            {isEmptyOrNull(iconName) ? (
              <RAFIconImage
                iconCssClass={"fa fa-group-arrows-rotate"}
                moduleavatar="bp-avatar transparent-avatar dashed-avatar"
                iconSize="30"
                fontSize="16"
              ></RAFIconImage>
            ) : (
              <div>
                <span
                  className="bp-avatar avatar-text transparent-avatar dashed-avatar"
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    maxWidth: "30px",
                    fontSize: "16px",
                  }}
                >
                  <IconPickerItem icon={iconName} size={16} color={"#0070d2"} />
                </span>
              </div>
            )}
            <div className="px-3" style={{ width: "calc(100% - 30px)" }}>
              <span className="bp-template-title">
                {fieldValue + versionNumber}
              </span>
              {/* {totalSteps > 0 && (
                      <div className="d-flex align-items-center">
                        <ul className="bp-step-ul" id={"bpUlRow_" + processUID}>
                          {bpStepRow}
                        </ul>
                        <span className="bp-template-content">
                          {totalSteps + " step(s)"}
                        </span>
                      </div>
                    )} */}
            </div>
          </div>
          <div className="bp-template-item">
            {showEdit === true && allowEditing === true ? (
              <div>
                <span
                  className={
                    fieldValue !== null && fieldValue !== undefined
                      ? " hover-hide-td-child-div-flex"
                      : "hover-hide-td-child-div-flex"
                  }
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    maxWidth: "30px",
                    fontSize: "12px",
                    fontWeight: 600,
                    marginRight: "8px",
                  }}
                >
                  {isEmptyOrNull(iconName) ? (
                    <RAFIconImage
                      iconCssClass={"fa fa-group-arrows-rotate"}
                      moduleavatar="bp-avatar"
                      iconSize="30"
                      fontSize="16"
                    ></RAFIconImage>
                  ) : (
                    <div>
                      <span
                        className="bp-avatar avatar-text"
                        style={{
                          width: "30px",
                          height: "30px",
                          minWidth: "30px",
                          maxWidth: "30px",
                          fontSize: "16px",
                        }}
                      >
                        <IconPickerItem icon={iconName} size={16} />
                      </span>
                    </div>
                  )}
                </span>
                <span
                  className={
                    fieldValue !== null && fieldValue !== undefined
                      ? "bp-avatar avatar-text e-editbutton hover-show-td-child-div-flex"
                      : "e-editbutton hover-show-td-child-div-flex"
                  }
                  style={{
                    width: "30px",
                    height: "30px",
                    minWidth: "30px",
                    maxWidth: "30px",
                    fontSize: "12px",
                    fontWeight: 600,
                    marginRight: "8px",
                  }}
                >
                  <span
                    className="fal fa-pencil"
                    style={{ fontSize: "12px" }}
                  ></span>
                </span>
              </div>
            ) : (
              <span
                className={
                  fieldValue !== null && fieldValue !== undefined ? "" : ""
                }
                style={{
                  width: "30px",
                  height: "30px",
                  minWidth: "30px",
                  maxWidth: "30px",
                  fontSize: "12px",
                  fontWeight: 600,
                  marginRight: "8px",
                }}
              >
                {isEmptyOrNull(iconName) ? (
                  <RAFIconImage
                    iconCssClass={"fa fa-group-arrows-rotate"}
                    moduleavatar="bp-avatar"
                    iconSize="30"
                    fontSize="16"
                  ></RAFIconImage>
                ) : (
                  <div>
                    <span
                      className="bp-avatar avatar-text"
                      style={{
                        width: "30px",
                        height: "30px",
                        minWidth: "30px",
                        maxWidth: "30px",
                        fontSize: "16px",
                      }}
                    >
                      <IconPickerItem icon={iconName} size={16} />
                    </span>
                  </div>
                )}
              </span>
            )}
            <div className="px-3" style={{ width: "calc(100% - 30px)" }}>
              <span className="bp-template-title">
                {fieldValue + versionNumber}
              </span>
              {/* {totalSteps > 0 && (
                      <div className="d-flex align-items-center">
                        <ul className="bp-step-ul" id={"bpUlRow_" + processUID}>
                          {bpStepRow}
                        </ul>
                        <span className="bp-template-content">
                          {totalSteps + " step(s)"}
                        </span>
                      </div>
                    )} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return;
};

export const versionHistoryListTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const processUID = data["UID"];
  const stepCount = data["StepCount"];
  const iconName: IconList = data["IconName"];
  const completedCount = data["CompletedCount"];
  const VersionNumber = data["VersionNumber"];
  const createdBy = data["CreatedBy"];
  const createdDate = data["CreatedDate"];
  let totalSteps =
    isNotNullAndUndefined(stepCount) && stepCount > 0 ? stepCount : 0;
  let completedSteps =
    isNotNullAndUndefined(completedCount) && completedCount > 0
      ? completedCount
      : 0;
  let bpStepRow = [];
  for (let i = 0; i < totalSteps; i++) {
    if (i < completedSteps) {
      bpStepRow.push(<li key={i} className="bp-step-li completed"></li>);
    } else {
      bpStepRow.push(<li key={i} className="bp-step-li"></li>);
    }
  }

  let versionNumber = isNotNullAndUndefined(VersionNumber)
    ? " (v" + VersionNumber + ")"
    : "";

  if (isNotNullAndUndefined(fieldValue)) {
    return (
      <div className="px-3" style={{ width: "calc(100% - 30px)" }}>
        <div className="detailsLeftCard-second-title h-auto row-d-inline-block">
          <div className="row gx-2 flex-nowrap align-items-center">
            <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
              <div className="detailsLeftCard-second-title-item">
                <span className="bp-template-title">
                  {fieldValue + versionNumber}
                </span>
              </div>
            </div>
            {isNotNullAndUndefined(createdDate) && (
              <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                <div className="detailsLeftCard-second-title-item">
                  <div className="ecllipseFirstLine">
                    <span>{moment(createdDate).format("DD/MM/YYYY")}</span>
                  </div>
                </div>
              </div>
            )}
            <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
              <div className="detailsLeftCard-second-title-item">
                <div className="ecllipseFirstLine noRowclick">{createdBy}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return;
};

export const checklistItemTransVersionHistoryListTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const VersionNumber = data["VersionNumber"];
  let versionNumber = isNotNullAndUndefined(VersionNumber)
    ? `V${VersionNumber}`
    : "";
  return (
    <div className="listGridItemOutter">
      <div className="listGridItemContent">
        <div className="listGridItem">
          <div className="subtitle_1">{versionNumber}</div>
          <RAFDetailsValueWithSeparator>
            <div className="body_2_light">{`By ${data.CreatedBy}`}</div>
            <div className="body_2_light">
              {`Expiry Date: ${isNotNullAndUndefined(data.ExpiryDate) ? moment(data.ExpiryDate).format("DD/MM/YYYY") : 'Not Set'}`}
            </div>
          </RAFDetailsValueWithSeparator>
        </div>
      </div>
    </div>
  );
};

export const revisionTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const revision = data["Revision"];
  const mimeType = data["MimeType"];
  const IsActive = data["IsActive"];
  let fileClassname;

  const fileNameExtension = data["FileName"];
  const fileExtension = getFileExtension(fileNameExtension);

  if (isNotNullAndUndefined(fileExtension)) {
    fileClassname = "fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;
  } else {
    fileClassname = "fiv-viv fiv-icon-blank fiv-icon-html";
  }

  // let versionNumber = isNotNullAndUndefined(VersionNumber)
  //   ? " (v" + VersionNumber + ")"
  //   : "";
  if (isNotNullAndUndefined(fieldValue)) {
    return (
      <div className="d-flex align-items-center">
        <TooltipComponent
          content={
            !IsNullOrWhiteSpace(mimeType)
              ? "Click to download"
              : "Click to preview"
          }
          position="BottomCenter"
        >
          <div className="d-flex align-items-center e-custom-actionbutton">
            <div className="d-flex document-item-icon">
              <span className={fileClassname}></span>
            </div>
            <span className="document-title ecllipseFirstLine">
              {"V" + revision}
            </span>
          </div>
        </TooltipComponent>
        {IsActive ? (
          <TooltipComponent content="Active" position="BottomCenter">
            <ButtonComponent
              type="button"
              iconCss="fas fa-check"
              cssClass="bg-transparent border-0 px-0 green-text"
            ></ButtonComponent>
          </TooltipComponent>
        ) : (
          <RAFPermissionRender
            permissionName={PermissionConstants.DocumentManage}
          >
            <TooltipComponent
              content="Click to set active"
              position="BottomCenter"
            >
              <ButtonComponent
                type="button"
                iconCss="fas fa-check"
                cssClass="bg-transparent border-0 px-0 e-custombutton hover-td-child-div"
              // onClick={(e) => { e.stopPropagation(); onSetActiveClick(documentVersionItem.UID); }}
              ></ButtonComponent>
            </TooltipComponent>
          </RAFPermissionRender>
        )}
      </div>
    );
  }
  return;
};

export const revisionListTemplate = (
  moduleName: string,
  allowEditing: boolean,
  showEdit: boolean,
  item: QueryAttributeJM,
  isDynamic: boolean,
  data,
  navigate: any,
  onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
  const fieldName = data.column.field;
  const fieldValue = data[fieldName];
  const revision = data["Revision"];
  const mimeType = data["MimeType"];
  const IsActive = data["IsActive"];
  let fileClassname;

  const fileNameExtension = data["FileName"];
  const fileExtension = getFileExtension(fileNameExtension);

  const createdBy = data["CreatedBy"];
  const createdDate = data["CreatedDate"];

  if (isNotNullAndUndefined(fileExtension)) {
    fileClassname = "fiv-viv fiv-icon-blank fiv-icon-" + fileExtension;
  } else {
    fileClassname = "fiv-viv fiv-icon-blank fiv-icon-html";
  }

  // let versionNumber = isNotNullAndUndefined(VersionNumber)
  //   ? " (v" + VersionNumber + ")"
  //   : "";
  if (isNotNullAndUndefined(fieldValue)) {
    return (
      <div className="d-flex align-items-center justify-content-between border-bottom e-custom-actionbutton bg-white pe-2">
        <div className="p-3">
          <div className="detailsLeftCard-second-title h-auto row-d-inline-block">
            <div className="row gx-2 flex-nowrap align-items-center">
              <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                <div className="detailsLeftCard-second-title-item">
                  <span className="header-text">{"v" + revision}</span>
                </div>
              </div>
              {isNotNullAndUndefined(createdDate) && (
                <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                  <div className="detailsLeftCard-second-title-item">
                    <div className="ecllipseFirstLine">
                      <span>{moment(createdDate).format("DD/MM/YYYY")}</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                <div className="detailsLeftCard-second-title-item">
                  <div className="ecllipseFirstLine noRowclick">
                    {createdBy}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {IsActive ? (
          <TooltipComponent content="Active" position="BottomCenter">
            <ButtonComponent
              type="button"
              iconCss="fas fa-check"
              cssClass="bg-transparent border-0 px-0 green-text"
            ></ButtonComponent>
          </TooltipComponent>
        ) : (
          <RAFPermissionRender
            permissionName={PermissionConstants.DocumentManage}
          >
            <TooltipComponent
              content="Click to set active"
              position="BottomCenter"
            >
              <ButtonComponent
                type="button"
                iconCss="fas fa-check"
                cssClass="bg-transparent border-0 px-0 e-custombutton hover-td-child-div"
              // onClick={(e) => { e.stopPropagation(); onSetActiveClick(documentVersionItem.UID); }}
              ></ButtonComponent>
            </TooltipComponent>
          </RAFPermissionRender>
        )}
      </div>
    );
  }
  return;
};
