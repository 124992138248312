import { DialogUtility } from "@syncfusion/ej2-popups";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import * as R from "ramda";
import React, { PropsWithChildren, useEffect } from "react";
import { FormRenderProps } from "react-final-form";
import { msalInstance } from "../../../..";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import RAFForm from "../../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../../RAFComponents/Inputs/RAFLookUpMUI";
import {
  getFormatedDate,
  setFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFEmptyState from "../../../../RAFComponents/Navigation/RAFEmptyState";
import { showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import {
  BrowserIsDevice,
  MomentFormats,
  RAFActionStatus,
  RAFButtonConstant,
  RAFLayout,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import UserProfilePhoto from "../../User/UserProfilePhoto";
import { ContentLibraryRow } from "../Library/ContentLibraryRow";
import {
  AcknowledgementTrackingRow,
  AcknowledgementTrackingSaveRow,
} from "./AcknowledgementTrackingRow";
import AcknowledgementTrackingSignSignature from "./AcknowledgementTrackingSignSignature";

const getAcknowledgementTrackingInitialValue = (
  contentLibraryRow: ContentLibraryRow,
  acknowledgementTrackingRetrieveResponseRow: AcknowledgementTrackingSaveRow,
  newAcknowledgementTrackingRetrieveResponseRow: AcknowledgementTrackingSaveRow
) => {
  return new Promise<AcknowledgementTrackingSaveRow>(async (resolve) => {
    if (isNotNullAndUndefined(contentLibraryRow)) {
      if (
        isNotNullAndUndefined(newAcknowledgementTrackingRetrieveResponseRow)
      ) {
        resolve(newAcknowledgementTrackingRetrieveResponseRow);
      } else if (
        isNotNullAndUndefined(acknowledgementTrackingRetrieveResponseRow)
      ) {
        resolve(acknowledgementTrackingRetrieveResponseRow);
      } else {
        if (isNotNullAndUndefined(contentLibraryRow)) {
          const acknowledgementTrackingRetrieveResponseRow =
            new AcknowledgementTrackingSaveRow();
          acknowledgementTrackingRetrieveResponseRow.RecordType = "Document";
          acknowledgementTrackingRetrieveResponseRow.DocumentUID =
            contentLibraryRow.UID;
          acknowledgementTrackingRetrieveResponseRow.Document =
            contentLibraryRow.DisplayName;
          acknowledgementTrackingRetrieveResponseRow.Status =
            RAFActionStatus.Pending;
          acknowledgementTrackingRetrieveResponseRow.VersionNumber =
            contentLibraryRow.Revision;
          acknowledgementTrackingRetrieveResponseRow.RequestedBy =
            msalInstance.currentUserName;
          acknowledgementTrackingRetrieveResponseRow.RequestedByUID =
            msalInstance.currentUserId;
          acknowledgementTrackingRetrieveResponseRow.RequestedDate = new Date();

          acknowledgementTrackingRetrieveResponseRow.Recipients = [];
          resolve(acknowledgementTrackingRetrieveResponseRow);
        } else {
          resolve(null);
        }
      }
    } else {
      resolve(null);
    }
  });
};

interface IProps {
  contentLibraryRow: ContentLibraryRow;
  recipientsAcknowledgementTrackingList: AcknowledgementTrackingRow[];
  acknowledgementTrackingRetrieveResponseRow: AcknowledgementTrackingSaveRow;
  onUpdateNewAcknowledgementTrackingRetrieveResponseRow: (
    newAcknowledgementTrackingRetrieveResponseRow: AcknowledgementTrackingSaveRow
  ) => void;
  mode: "default" | "Overview";
  selectedTabId: "NewRequest" | "RequestedAcknowledgement";
  newAcknowledgementTrackingRetrieveResponseRow: AcknowledgementTrackingSaveRow;
}

interface IState {
  displayMode: "view" | "edit";
  showSignSignatureDlgContent: boolean;
  selectedAcknowledgementTrackingRow: AcknowledgementTrackingRow;
}

function AcknowledgementTrackingListItemTabContent({
  contentLibraryRow,
  acknowledgementTrackingRetrieveResponseRow,
  ...props
}: PropsWithChildren<IProps>) {
  const outerDivId = `raf_dlg_Outer_Div_${RAFEntityName.AcknowledgementTracking}`;
  let rafForm: FormRenderProps | null;
  let deleteDialog: any;

  const [state, setState] = React.useReducer<
    React.Reducer<IState, Partial<IState>>
  >((state, newState) => ({ ...state, ...newState }), {
    displayMode: "view",
    showSignSignatureDlgContent: false,
    selectedAcknowledgementTrackingRow: null,
  });

  useEffect(() => {
    setState({
      displayMode: isNotNullAndUndefined(
        props.newAcknowledgementTrackingRetrieveResponseRow
      )
        ? "edit"
        : "view",
      showSignSignatureDlgContent: false,
      selectedAcknowledgementTrackingRow: null,
    });
  }, [
    acknowledgementTrackingRetrieveResponseRow,
    props.newAcknowledgementTrackingRetrieveResponseRow,
    contentLibraryRow,
    props.selectedTabId,
  ]);

  async function checkScrollable() {
    const div = await document.querySelector(
      "#raf_tab_header_acknowledgement_tracking"
    );
    const leftButton = await document.getElementById(
      "btn_raf_tab_header_acknowledgement_tracking_left_scroll_iconBtn"
    );
    const rightButton = await document.getElementById(
      "btn_raf_tab_header_acknowledgement_tracking_right_scroll_iconBtn"
    );

    if (div && leftButton && rightButton) {
      // Check if the div is scrollable
      if (div.scrollWidth > div.clientWidth) {
        // The div is scrollable, show the arrow buttons
        leftButton.style.display = "block";
        rightButton.style.display = "block";

        // Add click event listeners to the arrow buttons
        leftButton.addEventListener("click", () => {
          // Scroll the div to the left
          div.scrollTo({
            left: div.scrollLeft - (div.clientWidth - 100),
            behavior: "smooth",
          });
        });

        rightButton.addEventListener("click", () => {
          // Scroll the div to the right
          div.scrollTo({
            left: div.scrollLeft + (div.clientWidth - 100),
            behavior: "smooth",
          });
        });
      } else {
        // The div is not scrollable, hide the arrow buttons
        leftButton.style.display = "none";
        rightButton.style.display = "none";
      }

      // Stop observing the DOM changes
      observer.disconnect();
    }
  }

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(checkScrollable);

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  // Signature Dialog start
  const onSignSignatureBtnClick = (
    selectedAcknowledgementTrackingRow: AcknowledgementTrackingRow
  ) => {
    setState({
      showSignSignatureDlgContent: true,
      selectedAcknowledgementTrackingRow,
    });
  };

  const signSignatureDlgContent = () => {
    if (state.showSignSignatureDlgContent) {
      const { selectedAcknowledgementTrackingRow } = state;

      return (
        <AcknowledgementTrackingSignSignature
          acknowledgementTrackingRow={selectedAcknowledgementTrackingRow}
          mode="view"
        />
      );
    } else {
      return null;
    }
  };

  const signSignatureDlgClose = () => {
    setState({ showSignSignatureDlgContent: false });
  };
  // Signature Dialog end

  const onSubmitAcknowledgementTracking = async (newEmployee: LookUpRow) => {
    let progressDiv = showProgress(`#${outerDivId}`);
    const formEmployeeUID = newEmployee.UID;
    const formEmployee = newEmployee.Value;

    const { newAcknowledgementTrackingRetrieveResponseRow } = props;

    const acknowledgementTrackingInitialRow: AcknowledgementTrackingSaveRow =
      R.clone(
        await getAcknowledgementTrackingInitialValue(
          contentLibraryRow,
          acknowledgementTrackingRetrieveResponseRow,
          newAcknowledgementTrackingRetrieveResponseRow
        )
      );

    const acknowledgedBy = isNotEmptyArray(
      acknowledgementTrackingInitialRow.AcknowledgementRecipients
    )
      ? acknowledgementTrackingInitialRow.AcknowledgementRecipients
      : [];

    const selectedEmployeeUID: LookUpRow = {
      UID: formEmployeeUID,
      Value: formEmployee,
    };

    const itemExists = acknowledgedBy.some(
      (recipient) => recipient.UID === selectedEmployeeUID.UID
    );
    if (!itemExists) {
      const selectedAcknowledgementTrackingListValues: LookUpRow[] =
        isNotNullAndUndefined(acknowledgementTrackingRetrieveResponseRow)
          ? acknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients
          : [];
      const isItemExistInSelectedList = isNotEmptyArray(
        selectedAcknowledgementTrackingListValues
      )
        ? selectedAcknowledgementTrackingListValues.find(
          (recipient) => recipient.UID === selectedEmployeeUID.UID
        )
        : null;
      if (isNotNullAndUndefined(isItemExistInSelectedList)) {
        acknowledgedBy.push(isItemExistInSelectedList);
      } else {
        acknowledgedBy.push({
          UID: selectedEmployeeUID.UID,
          Value: selectedEmployeeUID.Value,
          Type: RAFEntityName.User,
        });
      }
    } else {
      hideProgress(progressDiv);
      showWarningToast("Employee already exists");
      return;
    }

    acknowledgementTrackingInitialRow.AcknowledgementRecipients =
      acknowledgedBy;
    if (props.onUpdateNewAcknowledgementTrackingRetrieveResponseRow) {
      props.onUpdateNewAcknowledgementTrackingRetrieveResponseRow(
        acknowledgementTrackingInitialRow
      );
    }
    hideProgress(progressDiv);
  };

  const onChangeEmployeeLookup = (label: string, value: string) => {
    if (isNotNullAndUndefined(value)) {
      onSubmitAcknowledgementTracking({ Value: label, UID: value });
    }
    setFormValue(rafForm, "Employee", null);
    setFormValue(rafForm, "EmployeeUID", null);
  };

  const getEmployeeMultiSelectLookUpDropdown = () => {
    const { newAcknowledgementTrackingRetrieveResponseRow } = props;
    const acknowledgedByListRow = isNotNullAndUndefined(
      newAcknowledgementTrackingRetrieveResponseRow
    )
      ? newAcknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients
      : isNotNullAndUndefined(acknowledgementTrackingRetrieveResponseRow)
        ? acknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients
        : [];

    const disableAcknowledgementTrackingDropDownItemsValue: LookUpRow[] = [];
    acknowledgedByListRow &&
      acknowledgedByListRow.forEach((item) => {
        if (isNotNullAndUndefined(item.UID))
          disableAcknowledgementTrackingDropDownItemsValue.push({
            Value: item.Value,
            UID: item.UID,
          });
      });

    return (
      <div
        className="pb-3"
        key={
          isNotEmptyArray(acknowledgedByListRow)
            ? acknowledgedByListRow.length
            : 0
        }
      >
        <RAFForm
          formRef={(g) => (rafForm = g)}
          layout={RAFLayout.TwoColumnLayout}
          onSubmit={(values) => onSubmitAcknowledgementTracking(values)}
          className="h-100"
        >
          <div className="row g-2">
            <div className="col">
              <RAFLookUpMUI
                field={"Employee"}
                formGroupClassName="m-0"
                showLabel={false}
                placeholder="Select Recipient"
                disableItems={disableAcknowledgementTrackingDropDownItemsValue}
                required
                url="User/EmployeeLookup"
                onChanged={onChangeEmployeeLookup}
              />
              {/* <RAFDropdownCC field={`EmployeeUID`}
                                formGroupClassName="m-0" showLabel={false} placeholder="Select Recipient"
                                disableItems={disableAcknowledgementTrackingDropDownItemsValue}
                                onChanged={(label, value) => { }}
                                showClearButton={true}
                                required
                                allowFiltering
                            >
                                {props.allEmployeeLookUpList && props.allEmployeeLookUpList.map((item, index) => {
                                    return <RAFChoiceOption key={index} label={item.Value} value={item.UID} />;
                                })
                                }
                            </RAFDropdownCC> */}
            </div>
          </div>
        </RAFForm>
      </div>
    );
  };

  //delete Remove Shift Offer item start
  const onRemoveAcknowledgementTrackingBtnClick = (
    acknowledgementTrackingRecipientUID: string,
    acknowledgementTrackingRecipient: string
  ) => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: `Are you sure want to remove ${acknowledgementTrackingRecipient}?`,
      okButton: {
        text: "Yes",
        click: deleteRecord.bind(this, acknowledgementTrackingRecipientUID),
      },
      title: `Remove Recipient`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord(acknowledgementTrackingRecipientUID: string) {
    let progressDiv = showProgress(".deleteDialog.e-dialog");
    const acknowledgementTrackingInitialRow: AcknowledgementTrackingSaveRow =
      R.clone(
        await getAcknowledgementTrackingInitialValue(
          contentLibraryRow,
          acknowledgementTrackingRetrieveResponseRow,
          props.newAcknowledgementTrackingRetrieveResponseRow
        )
      );
    const acknowledgedBy = isNotEmptyArray(
      acknowledgementTrackingInitialRow.AcknowledgementRecipients
    )
      ? acknowledgementTrackingInitialRow.AcknowledgementRecipients.filter(
        (x) => x.UID !== acknowledgementTrackingRecipientUID
      )
      : [];
    acknowledgementTrackingInitialRow.AcknowledgementRecipients =
      acknowledgedBy;

    if (props.onUpdateNewAcknowledgementTrackingRetrieveResponseRow) {
      props.onUpdateNewAcknowledgementTrackingRetrieveResponseRow(
        acknowledgementTrackingInitialRow
      );
    }

    deleteDialog.hide();
    hideProgress(progressDiv);
  }
  //delete item end

  const getEmployeeLookupContent = (
    acknowledgedByListRow: LookUpRow[],
    showEmptyMessage: boolean
  ) => {
    const { recipientsAcknowledgementTrackingList } = props;

    const sortedAcknowledgedByListRow: LookUpRow[] = isNotEmptyArray(
      acknowledgedByListRow
    )
      ? acknowledgedByListRow.sort((a, b) => a.Value.localeCompare(b.Value))
      : [];

    if (isNotEmptyArray(sortedAcknowledgedByListRow)) {
      return (
        <div className="row gx-0 gy-3">
          {sortedAcknowledgedByListRow.map((acknowledgedByRow: LookUpRow) => {
            const acknowledgementTrackingItem = isNotEmptyArray(
              recipientsAcknowledgementTrackingList
            )
              ? recipientsAcknowledgementTrackingList.find(
                (item) => item.SignedByUID === acknowledgedByRow.UID
              )
              : null;

            return (
              <div className="col-12" key={acknowledgedByRow.UID}>
                <CustomCardWidget
                  cardClassName="surface_neutral_base"
                  cardContentClassName="p-2 px-2-5"
                >
                  <div className="row gx-2 align-items-center">
                    {BrowserIsDevice ? (
                      <div
                        className="col-auto"
                        id={`employee_${acknowledgedByRow.UID}`}
                      >
                        <div>
                          <UserProfilePhoto
                            uid={acknowledgedByRow.UID}
                            relatedToType={RAFEntityName.User}
                            canEdit={false}
                            profileImgCss={"profileImg-container-xmd"}
                            progressDivID={`#employee_${acknowledgedByRow.UID}`}
                            title={acknowledgedByRow.Value}
                            iconSize="48"
                            fontSize="16"
                            emptyAvatarType="Initial"
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="col-auto"
                        id={`employee_${acknowledgedByRow.UID}`}
                      >
                        <UserProfilePhoto
                          uid={acknowledgedByRow.UID}
                          relatedToType={RAFEntityName.User}
                          canEdit={false}
                          profileImgCss={"profileImg-container-xmd"}
                          progressDivID={`#employee_${acknowledgedByRow.UID}`}
                          title={acknowledgedByRow.Value}
                          iconSize="48"
                          fontSize="16"
                          emptyAvatarType="Initial"
                        />
                      </div>
                    )}
                    <div className="col d-flex">
                      <div>
                        <div>
                          <span className="header_6 semi_bold mb-1 ecllipseFirstLine">
                            {acknowledgedByRow.Value}
                          </span>
                        </div>
                        <div className="row gx-2 gy-0">
                          <RAFDetailsValueWithSeparator outerClassName="h-auto raf_md">
                            {isNotNullAndUndefined(
                              acknowledgementTrackingItem
                            ) &&
                              isNotNullAndUndefined(
                                acknowledgementTrackingItem.Status
                              ) && (
                                <RAFDetailFieldCustom
                                  value={
                                    isNotNullAndUndefined(
                                      acknowledgementTrackingItem.Status
                                    )
                                      ? acknowledgementTrackingItem.Status
                                      : "Not set"
                                  }
                                  displayValue={
                                    acknowledgementTrackingItem.Status
                                  }
                                  title="Status:"
                                  moduleName={
                                    RAFEntityName.AcknowledgementTracking
                                  }
                                  isColorOption
                                  field="Status"
                                  showLabel={true}
                                  rowClassName="gx-1 align-items-baseline"
                                  labelDivClassName="col-auto"
                                  valueDivClassName="col-auto"
                                  labelClassName="body_2_light content_neutral_base"
                                  valueClassName="subtitle_2 semi_bold"
                                  mode="textOnly"
                                />
                              )}
                            {isNotNullAndUndefined(
                              acknowledgementTrackingItem
                            ) &&
                              isNotNullAndUndefined(
                                acknowledgementTrackingItem.RequestedBy
                              ) && (
                                <div className="col-auto">
                                  <span className="body_2_light">
                                    Requested By:
                                    <span className="body_2 semi_bold ps-1">
                                      {acknowledgementTrackingItem.RequestedBy}
                                    </span>
                                  </span>
                                </div>
                              )}
                            {isNotNullAndUndefined(
                              acknowledgementTrackingItem
                            ) &&
                              (isNotNullAndUndefined(
                                acknowledgementTrackingItem.SignedDate
                              ) ? (
                                <div className="col-auto">
                                  <span className="body_2_light pe-1">
                                    Response On:
                                    <span className="body_2 semi_bold ps-1">
                                      {getFormatedDate(
                                        acknowledgementTrackingItem.SignedDate,
                                        MomentFormats.DATE
                                      )}
                                    </span>
                                  </span>
                                </div>
                              ) : isNotNullAndUndefined(
                                acknowledgementTrackingItem.RequestedDate
                              ) ? (
                                <div className="col-auto">
                                  <span className="body_2_light pe-1">
                                    Requested On:
                                    <span className="body_2 semi_bold ps-1">
                                      {getFormatedDate(
                                        acknowledgementTrackingItem.RequestedDate,
                                        MomentFormats.DATE
                                      )}
                                    </span>
                                  </span>
                                </div>
                              ) : (
                                ""
                              ))}
                          </RAFDetailsValueWithSeparator>
                          {/* {(isNotNullAndUndefined(acknowledgementTrackingItem) &&
                                                          isNotNullAndUndefined(acknowledgementTrackingItem.Status)) && (
                                                              <div className="col-auto">
                                                                  <span className="body_2_light">
                                                                      Status:
                                                                      <span className="body_2 ms-1">
                                                                          {acknowledgementTrackingItem.Status}
                                                                      </span>
                                                                  </span>
                                                              </div>
                                                          )} */}
                        </div>
                      </div>
                    </div>
                    {isNotNullAndUndefined(acknowledgementTrackingItem) &&
                      acknowledgementTrackingItem.Status ===
                      RAFActionStatus.Acknowledged ? (
                      <div className="col-auto">
                        <RAFButtonComponent
                          type="button"
                          className="new_style link-button raf_sm"
                          btnContent="Check"
                          onClick={() =>
                            onSignSignatureBtnClick(acknowledgementTrackingItem)
                          }
                        />
                      </div>
                    ) : (
                      props.mode === "default" && (
                        <div className="col-auto">
                          <RAFPermissionRender permissionName={PermissionConstants.DocumentAcknowledgementTrackingSend}>
                            <RAFButtonComponent
                              type="button"
                              btnContent="Click to remove"
                              className="btn_state_danger white"
                              onClick={() =>
                                onRemoveAcknowledgementTrackingBtnClick(
                                  acknowledgedByRow.UID,
                                  acknowledgedByRow.Value
                                )
                              }
                              enableToolTip
                              iconBtn
                              action={RAFButtonConstant.Delete}
                            />
                          </RAFPermissionRender>
                        </div>
                      )
                    )}
                  </div>
                </CustomCardWidget>
              </div>
            );
          })}
        </div>
      );
    } else if (showEmptyMessage) {
      if (props.selectedTabId === "NewRequest") {
        return (
          <div>
            <RAFEmptyState
              svgContent={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
                    fill="#686869"
                  />
                  <path
                    d="M14.0809 14.1499C11.2909 12.2899 6.74094 12.2899 3.93094 14.1499C2.66094 14.9999 1.96094 16.1499 1.96094 17.3799C1.96094 18.6099 2.66094 19.7499 3.92094 20.5899C5.32094 21.5299 7.16094 21.9999 9.00094 21.9999C10.8409 21.9999 12.6809 21.5299 14.0809 20.5899C15.3409 19.7399 16.0409 18.5999 16.0409 17.3599C16.0309 16.1299 15.3409 14.9899 14.0809 14.1499Z"
                    fill="#686869"
                  />
                  <path
                    d="M19.9894 7.3401C20.1494 9.2801 18.7694 10.9801 16.8594 11.2101C16.8494 11.2101 16.8494 11.2101 16.8394 11.2101H16.8094C16.7494 11.2101 16.6894 11.2101 16.6394 11.2301C15.6694 11.2801 14.7794 10.9701 14.1094 10.4001C15.1394 9.4801 15.7294 8.1001 15.6094 6.6001C15.5394 5.7901 15.2594 5.0501 14.8394 4.4201C15.2194 4.2301 15.6594 4.1101 16.1094 4.0701C18.0694 3.9001 19.8194 5.3601 19.9894 7.3401Z"
                    fill="#686869"
                  />
                  <path
                    d="M21.9883 16.5904C21.9083 17.5604 21.2883 18.4004 20.2483 18.9704C19.2483 19.5204 17.9883 19.7804 16.7383 19.7504C17.4583 19.1004 17.8783 18.2904 17.9583 17.4304C18.0583 16.1904 17.4683 15.0004 16.2883 14.0504C15.6183 13.5204 14.8383 13.1004 13.9883 12.7904C16.1983 12.1504 18.9783 12.5804 20.6883 13.9604C21.6083 14.7004 22.0783 15.6304 21.9883 16.5904Z"
                    fill="#686869"
                  />
                </svg>
              }
              title="No Recipients Selected"
              body="Start by choosing a person to send an acknowledgement request."
            />
          </div>
        );
      } else {
        return (
          <div>
            <RAFEmptyState
              svgContent={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
                    fill="#686869"
                  />
                  <path
                    d="M14.0809 14.1499C11.2909 12.2899 6.74094 12.2899 3.93094 14.1499C2.66094 14.9999 1.96094 16.1499 1.96094 17.3799C1.96094 18.6099 2.66094 19.7499 3.92094 20.5899C5.32094 21.5299 7.16094 21.9999 9.00094 21.9999C10.8409 21.9999 12.6809 21.5299 14.0809 20.5899C15.3409 19.7399 16.0409 18.5999 16.0409 17.3599C16.0309 16.1299 15.3409 14.9899 14.0809 14.1499Z"
                    fill="#686869"
                  />
                  <path
                    d="M19.9894 7.3401C20.1494 9.2801 18.7694 10.9801 16.8594 11.2101C16.8494 11.2101 16.8494 11.2101 16.8394 11.2101H16.8094C16.7494 11.2101 16.6894 11.2101 16.6394 11.2301C15.6694 11.2801 14.7794 10.9701 14.1094 10.4001C15.1394 9.4801 15.7294 8.1001 15.6094 6.6001C15.5394 5.7901 15.2594 5.0501 14.8394 4.4201C15.2194 4.2301 15.6594 4.1101 16.1094 4.0701C18.0694 3.9001 19.8194 5.3601 19.9894 7.3401Z"
                    fill="#686869"
                  />
                  <path
                    d="M21.9883 16.5904C21.9083 17.5604 21.2883 18.4004 20.2483 18.9704C19.2483 19.5204 17.9883 19.7804 16.7383 19.7504C17.4583 19.1004 17.8783 18.2904 17.9583 17.4304C18.0583 16.1904 17.4683 15.0004 16.2883 14.0504C15.6183 13.5204 14.8383 13.1004 13.9883 12.7904C16.1983 12.1504 18.9783 12.5804 20.6883 13.9604C21.6083 14.7004 22.0783 15.6304 21.9883 16.5904Z"
                    fill="#686869"
                  />
                </svg>
              }
              title="No Acknowledgement Requests Sent"
              body="Once you select a recipient and send a request, their details will appear here."
            />
          </div>
        );
      }
    } else {
      return <></>;
    }
  };

  const getNewRequestContent = () => {
    if (props.mode === "default") {
      const { recipientsAcknowledgementTrackingList } = props;

      const { newAcknowledgementTrackingRetrieveResponseRow } = props;

      const allAcknowledgedByListRow = isNotNullAndUndefined(
        newAcknowledgementTrackingRetrieveResponseRow
      )
        ? newAcknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients
        : [];

      const acknowledgedByListRow =
        isNotEmptyArray(allAcknowledgedByListRow) &&
          isNotEmptyArray(recipientsAcknowledgementTrackingList)
          ? allAcknowledgedByListRow.filter(
            (x) =>
              !recipientsAcknowledgementTrackingList.find(
                (y) => y.SignedByUID === x.UID
              )
          )
          : allAcknowledgedByListRow;

      return (
        <>
          <div className="col-md-12">
            <div className="subtitle_1 semi_bold">New Request</div>
          </div>
          <div className="col-md-12">
            {getEmployeeMultiSelectLookUpDropdown()}
            {getEmployeeLookupContent(acknowledgedByListRow, true)}
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const getRequestedContent = () => {
    const { recipientsAcknowledgementTrackingList } = props;

    const { newAcknowledgementTrackingRetrieveResponseRow } = props;

    const allAcknowledgedByListRow = isNotNullAndUndefined(
      newAcknowledgementTrackingRetrieveResponseRow
    )
      ? newAcknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients
      : isNotNullAndUndefined(acknowledgementTrackingRetrieveResponseRow)
        ? acknowledgementTrackingRetrieveResponseRow.AcknowledgementRecipients
        : [];

    const pendingItems =
      isNotEmptyArray(allAcknowledgedByListRow) &&
        isNotEmptyArray(recipientsAcknowledgementTrackingList)
        ? allAcknowledgedByListRow.filter((x) =>
          recipientsAcknowledgementTrackingList.find(
            (y) =>
              y.SignedByUID === x.UID && y.Status === RAFActionStatus.Pending
          )
        )
        : [];

    const acknowledgedItems =
      isNotEmptyArray(allAcknowledgedByListRow) &&
        isNotEmptyArray(recipientsAcknowledgementTrackingList)
        ? allAcknowledgedByListRow.filter((x) =>
          recipientsAcknowledgementTrackingList.find(
            (y) =>
              y.SignedByUID === x.UID &&
              y.Status === RAFActionStatus.Acknowledged
          )
        )
        : [];

    const rejectedItems =
      isNotEmptyArray(allAcknowledgedByListRow) &&
        isNotEmptyArray(recipientsAcknowledgementTrackingList)
        ? allAcknowledgedByListRow.filter((x) =>
          recipientsAcknowledgementTrackingList.find(
            (y) =>
              y.SignedByUID === x.UID && y.Status === RAFActionStatus.Rejected
          )
        )
        : [];

    return (
      <>
        <div className="col-md-12">
          <div className="subtitle_1 semi_bold">Pending Requests</div>
        </div>
        <div className="col-md-12">
          {getEmployeeLookupContent(pendingItems, true)}
        </div>
        {isNotEmptyArray(acknowledgedItems) && (
          <>
            <div className="col-md-12">
              <div className="custom__card__separator custom__card__separator__light"></div>
            </div>
            <div className="col-md-12">
              <div className="subtitle_1 semi_bold">Received Requests</div>
            </div>
            <div className="col-md-12">
              {getEmployeeLookupContent(acknowledgedItems, true)}
            </div>
          </>
        )}
        {isNotEmptyArray(rejectedItems) && (
          <>
            <div className="col-md-12">
              <div className="custom__card__separator custom__card__separator__light"></div>
            </div>
            <div className="col-md-12">
              <div className="subtitle_1 semi_bold">Rejected Requests</div>
            </div>
            <div className="col-md-12">
              {getEmployeeLookupContent(rejectedItems, true)}
            </div>
          </>
        )}
      </>
    );
  };

  function renderTabContent() {
    if (props.selectedTabId === "NewRequest") {
      return <>{getNewRequestContent()}</>;
    } else if (props.selectedTabId === "RequestedAcknowledgement") {
      return <>{getRequestedContent()}</>;
    }
  }

  return (
    <div className="row gx-0 gy-3" id={outerDivId}>
      {renderTabContent()}
      {state.showSignSignatureDlgContent && (
        <DialogComponent
          header={"Confirm Acknowledgement"}
          visible={state.showSignSignatureDlgContent}
          cssClass="centerDialog-md createEditForm full-height form-center-dialog dlg-new-style fixed-header centerDialog-height-lg dlg_res_full_page"
          content={signSignatureDlgContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={signSignatureDlgClose.bind(this)}
          open={PreventFocusOnDialogOpen}
          showCloseIcon
          zIndex={1500}
        />
      )}
    </div>
  );
}

export default React.memo(AcknowledgementTrackingListItemTabContent);
