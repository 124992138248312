import DOMPurify from "dompurify";
import createDecorator from "final-form-calculate";
import React, {
  PropsWithChildren,
  forwardRef,
  useImperativeHandle,
} from "react";
import { FormRenderProps } from "react-final-form";
import RAFForm from "../../../../RAFComponents/Inputs/RAFForm";
import RAFHtmlEditor from "../../../../RAFComponents/Inputs/RAFHtmlEditor";
import RAFTextBox from "../../../../RAFComponents/Inputs/RAFTextBox";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  getSaveRequest,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import {
  ContentType,
  RAFButtonConstant,
  RAFLayout,
} from "../../../../constants/Common/Constants";
import "./../DocumentStyle.scss";
import { ContentLibraryRow } from "./ContentLibraryRow";
import { ContentLibraryCurrentStatus } from "./DocumentHelper";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";

interface IProps {
  contentLibraryRow?: ContentLibraryRow;
  documentParentRow?: ContentLibraryRow;
  refreshOnUpdate?: () => void;
  onSaveBtnClick?: () => void;
  onCancelClick?: () => void;
  onEditClick?: () => void;
  showManagePageDocContent?: boolean;
}

const RAFHtmlPageContentDetails = forwardRef(function RAFHtmlPageContentDetails(
  { ...props }: PropsWithChildren<IProps>,
  ref
) {
  let rafForm: FormRenderProps | null;

  useImperativeHandle(ref, () => ({
    onSubmitBtnClick() {
      if (isNotNullAndUndefined(rafForm)) {
        rafForm.form.submit();
      }
    },
  }));

  const refreshOnUpdate = async () => {
    if (isNotNullAndUndefined(props.refreshOnUpdate)) {
      props.refreshOnUpdate();
    }
  };
  const onEditClick = async () => {
    if (isNotNullAndUndefined(props.onEditClick)) {
      props.onEditClick();
    }
  };
  const onCancelClick = async () => {
    if (isNotNullAndUndefined(props.onCancelClick)) {
      props.onCancelClick();
    }
  };
  const onSaveBtnClick = async () => {
    if (isNotNullAndUndefined(props.onSaveBtnClick)) {
      props.onSaveBtnClick();
    }
  };

  const onSubmitWebPageDoc = (value: ContentLibraryRow) => {
    const contentLibraryRow: ContentLibraryRow = { ...value };
    let progressDiv = showProgress("body");

    const url =
      contentLibraryRow.CurrentStatus == ContentLibraryCurrentStatus.Published
        ? "ContentLibrary/RevisePage"
        : "ContentLibrary/Save";

    repositoryActions
      .postDataAndGetResponse(
        url,
        //'ContentLibrary/SaveSubPage',
        getSaveRequest(contentLibraryRow, contentLibraryRow.UID),
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId) &&
          isNotNullAndUndefined(response.data.ObjectName)
        ) {
          showSuccessToast("Updated Successfully");
          refreshOnUpdate();
        } else {
          showWarningToast("Sorry something went wrong !");
        }
      })
      .catch((error) => error);
  };

  const contentLibraryDecorators = createDecorator({
    field: "DisplayName",
    updates: {
      FileName: (minimumValue, allValues: ContentLibraryRow) =>
        allValues.DisplayName,
    },
  });

  const { contentLibraryRow } = props;

  return (
    <div
      className="detailspage-container document_page_container"
      key={`manage_web_Page_Div_${
        isNotNullAndUndefined(props.showManagePageDocContent)
          ? props.showManagePageDocContent.toString()
          : ""
      }`}
    >
      <RAFForm
        type={ContentLibraryRow}
        initialValues={contentLibraryRow}
        formRef={(g) => (rafForm = g)}
        layout={RAFLayout.TwoColumnLayout}
        onSubmit={onSubmitWebPageDoc}
        decorators={[contentLibraryDecorators]}
        submitOnEnterKey={false}
        className="h-100 section__container"
      >
        <div className="section__div section__divY customScrollBar">
          <div className="h-100" id="manage_web_Page_Div">
            <div className="doc_details_html_content_container">
              {props.showManagePageDocContent ? (
                <>
                  <div className="row gx-3">
                    <div className="col">
                      <RAFTextBox<ContentLibraryRow>
                        field="DisplayName"
                        label="Name"
                        placeholder="File name"
                        required
                        showLabel={false}
                        inputFieldClassName="document_page_header_input"
                      />
                    </div>
                    <div className="col-auto">
                      <div className="row gx-2 gy-0">
                        <>
                          <div className="col-auto">
                            <RAFButtonComponent
                              action={RAFButtonConstant.Save}
                              className="btn_brand_primary semi_dark"
                              onClick={onSaveBtnClick}
                            />
                          </div>
                          <div className="col-auto">
                            <RAFButtonComponent
                              action={RAFButtonConstant.Cancel}
                              className="btn_neutral outline"
                              onClick={onCancelClick}
                            />
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                  <RAFHtmlEditor<ContentLibraryRow>
                    field="PageJson"
                    label="Notes"
                    showLabel={false}
                    placeholder="Notes"
                    labelClassName="bp-label col-12"
                    inputFieldClassName="bp-Input col-12"
                    required
                    htmlEditorHeight={"100%"}
                    formGroupClassName="mb-0"
                    fullHeight
                  />
                </>
              ) : (
                <>
                  <div className="row gx-3">
                    <div className="col">
                      <RAFTextBox<ContentLibraryRow>
                        field="DisplayName"
                        label="Name"
                        placeholder="File name"
                        required
                        showLabel={false}
                        inputFieldClassName="document_page_header_input"
                        disabled={true}
                        formGroupClassName="mb-1"
                      />
                    </div>
                    <div className="col-auto">
                      <RAFPermissionRender
                        permissionName={PermissionConstants.DocumentManage}
                      >
                        <RAFButtonComponent
                          action={RAFButtonConstant.Edit}
                          className="btn_brand_primary semi_dark"
                          onClick={onEditClick}
                          showIcon={false}
                          btnContent={`${RAFButtonConstant.Edit.DisplayName} Page`}
                        />
                      </RAFPermissionRender>
                    </div>
                  </div>
                  {isNotNullAndUndefined(contentLibraryRow) &&
                  isNotNullAndUndefined(contentLibraryRow.PageJson) ? (
                    <RAFHtmlEditor<ContentLibraryRow>
                      field="PageJson"
                      label="Notes"
                      showLabel={false}
                      placeholder="Notes"
                      labelClassName="bp-label col-12"
                      inputFieldClassName="bp-Input col-12"
                      required
                      htmlEditorHeight={"100%"}
                      formGroupClassName="mb-0"
                      fullHeight
                      disabled
                    />
                  ) : (
                    <div className="p-3">
                      <span
                        className={
                          "key_value ecllipseFirstLine text-wrap word-break-all description-text"
                        }
                        dangerouslySetInnerHTML={{
                          __html:
                            isNotNullAndUndefined(contentLibraryRow) &&
                            isNotNullAndUndefined(contentLibraryRow.PageJson)
                              ? DOMPurify.sanitize(contentLibraryRow.PageJson)
                              : "Content not available.",
                        }}
                      ></span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </RAFForm>
    </div>
  );
});

export default React.memo(RAFHtmlPageContentDetails);
